import React, { useContext, useEffect, useRef, useState } from "react";
import "../partners/partners.scss";
import RunningLine from "../runningLine/RunningLine";
import { Context } from "../../App";
import BackElips from "../backElips/BackElips";

const partnersData = [
  "1.svg",
  "2.svg",
  "3.svg",
  "4.svg",
  "5.svg",
  "6.svg",
  "7.svg",
  "8.svg",
];

function BricksRender() {
  return (
    <>
      {partnersData.map((el, index) => (
        <div key={`PSBE_${index}`} className="PartnersSection_Bricks_El"> 
          <img src={`./img/partners/${el}`} />
        </div>
      ))}
    </>
  );
}

function Partners() {
  const screenWidth = useContext(Context).screenWidth;

  return (
    <div className="PartnersSection_Wrap">
      <div className="PartnersSection">
        <div className="PartnersSection_Text">
          BUILDING DIGITAL COMMUNITIES FOR FUTURE <span>MARKET LEADERS! </span>
        </div>
        <div className="PartnersSection_Bricks">
          {screenWidth <= 1080 && screenWidth != 0 ? (
            <div className="PartnersSection_Bricks_RunningLine">
              <RunningLine height={"130px"} childWidth="2500px">
                <div className="PartnersSection_Bricks_Wrap">
                  {partnersData.map((el, index) => (
                    <div
                      key={`PSBE_${index}`}
                      className="PartnersSection_Bricks_Wrap_El"
                    >
                      <img src={`./img/partners/${el}`} />
                    </div>
                  ))}
                </div>
              </RunningLine>
            </div>
          ) : (
            <BricksRender />
          )}
        </div>
        </div> 
      </div>
  );
}

export default Partners;
