import React from "react";
import "../casestudyLarge/casestudylarge.scss";
import { Context } from "../../App";

export default function Component() {
  return (
    <>


    </>
  );
}
