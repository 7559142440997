import React from 'react'
import '../bottomText/bottomText.scss'

function BottomText() {
  return (
    <div className="BottomTextSection">
      <div className="BottomTextSection_Image">
        {/* <img className='BottomTextSection_Image_El' src="../img/bottom_text.png" /> */}
      </div>
    </div>
  )
}

export default BottomText
