import React from "react";
import "../casestudy/casestudy.scss";
import { Context } from "../../App";

export default function Component() {
  return (
    <>
  <section className="section">
    <div className="padding-global padding-none-tablet">
      <div className="container-large">
        <div className="padding-section-medium">
          <div className="our-work_component">
            <div className="collection-wrapper w-dyn-list">
              <div className="collection-double-feature-list w-dyn-items" role="list">
              <div className="work-collection-item w-dyn-item" role="listitem">
                    <a className="our-work_tile w-inline-block" target="_blank" href="https://smartcollectibles.io/" rel="noreferrer">
                        <div className="absolute-wrapper">
                            <img
                                loading="lazy"
                                className="img-cover align-top zoom"
                                height={471}
                                width={617}
                                alt={`Assassin's Creed Banner Picture`}
                                src="./img/casestudy/assassins_creed_banner.webp"
                            />
                        </div>
                        <div className="brand-logo-wrapper"> 
                            <img
                                loading="lazy"
                                className="brand-logo"
                                height={55}
                                width={177}
                                alt="Assassin's Creed Logo"
                                src="./img/casestudy/assassins _creed_logo.webp" 
                            />
                        </div>
                          <div className="our-work_tile-content">
                            <div className="stats-bar">
                              <div className="ourwork-stat-item">
                                  <div className="ourwork-stat is-small">11.50% +</div>
                                  <div className="ourwork-stat-title is-small">Return on investment</div>
                              </div>
                              <div className="ourwork-stat-item">
                                  <div className="ourwork-stat is-small">20,000 +</div>
                                  <div className="ourwork-stat-title is-small">Community Size</div>
                              </div>
                              <div className="ourwork-stat-item">
                                  <div className="ourwork-stat is-small">1 Million +</div>
                                  <div className="ourwork-stat-title is-small">Impressions</div>
                              </div>
                              <div className="ourwork-stat-item">
                                  <div className="ourwork-stat is-small">1,000 +</div>
                                  <div className="ourwork-stat-title is-small">Live Listeners</div>
                              </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="work-collection-item w-dyn-item" role="listitem">
                  <a className="our-work_tile w-inline-block" target="_blank" href="https://opensea.io/collection/bullsandapes-genesis" rel="noreferrer">
                      <div className="absolute-wrapper zoom">
                          <img
                              loading="lazy"
                              className="img-cover align-top zoom"
                              height={471}
                              width={617}
                              alt="Bulls & Apes Banner Picture"
                              src="./img/casestudy/bulls_and_apes_banner.webp"
                          />
                      </div>
                      <div className="brand-logo-wrapper">
                          <img
                              loading="lazy"
                              className="brand-logo"
                              height={53}
                              width={177}
                              alt="Bulls & Apes Logo"
                              src="./img/casestudy/bulls_and_apes_logo.webp"
                          />
                      </div>
                      <div className="our-work_tile-content">
                        <div className="stats-bar">
                              <div className="ourwork-stat-item">
                                <div className="ourwork-stat is-small">5 Sold Out</div>
                                <div className="ourwork-stat-title is-small">Collections</div>
                              </div>
                              <div className="ourwork-stat-item">
                                <div className="ourwork-stat is-small">$10 + Million</div>
                                <div className="ourwork-stat-title is-small">Secondary Sales</div>
                              </div>
                              <div className="ourwork-stat-item">
                                <div className="ourwork-stat is-small">20 + Million</div>
                                <div className="ourwork-stat-title is-small">Impressions</div>
                              </div>
                              <div className="ourwork-stat-item">
                                <div className="ourwork-stat is-small">50k +</div>
                                <div className="ourwork-stat-title is-small">Live Listeners</div>
                              </div>
                          </div>                      
                        </div>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  );
}
