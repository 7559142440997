import React, { useEffect, useRef, useState } from "react";
import BookButton from "../bookButton/BookButton";
import "../network/network.scss";
import RunningLine from "../runningLine/RunningLine";

function Network() {
  const networkData = [
    "1.png",
    "2.png",
    "3.png",
    "4.png",
    "5.png",
    "6.png",
    "7.png",
    "8.png",
    "9.png",
    "10.png",
    "11.png",
    "12.png",
  ]
  // const wrapRef = useRef(null)


  
  return (
<div id="network_section" class="NetworkSection_Wrap">
  <div class="NetworkSection">
    <div class="NetworkSection_Pin">From Influencer to Investor</div>
    <div class="NetworkSection_BigText">
      Bluship's Web3 Fundraising Model: Turning KOLs into Investors
    </div>
    <div class="NetworkSection_SmallText">
      Traditional ways of working with influencers are proving to be more and more ineffective, especially when done incorrectly.
      <p>
        The old way? Chasing KOLs, blowing cash on temporary attention, and sinking money into deals where only the influencer wins—not you.
      </p>
      <p>
        So we decided to stop the madness and try something new.
      </p>
      <p>
        Now, imagine this: a group of influencers who pay you anywhere from $2,000 to over $100,000 because they want to talk about your project. They're just as committed to its success as you are. That's the reality for our partners:
      </p>
      <ul>
        <li>Ordiswap assited with raising $150,000+ in 48 hours</li>
        <li>Another-1 assited with raising $100,000+ in 1 week</li>
        <li>Lunar Storm assited with raising $100,000+ in 1 week</li>
        <li>Altcoinist assited with raising $100,000+ in 1 week</li>
      </ul>
      <p>
        Influencers now become stakeholders in your project, investing to spread your story — across their networks. We turn influencers into investors.
      </p>
      <p>
        It's a win-win: Your project gets the attention it deserves, while the influencers benefit long-term as they watch your token's value grow.
      </p>
    </div>

        <BookButton />

        <div className="NetworkSection_Back_Bottom">
          <RunningLine >
            <div className="NetworkSection_Back_Bottom_Wrap" >
              {networkData.map((el, index) => (
                <div
                  key={`NSBBW_${index}`}
                  className="NetworkSection_Back_Bottom_Wrap_El"
                >
                  <img src={`./img/network/${el}`} key={`NBBWE_${index}`} />
                </div>
              ))}
            </div>
          </RunningLine>
        </div>
      </div>
    </div>
  );
}

export default Network;
